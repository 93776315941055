import React, { useRef, useEffect, useState } from 'react';
import ListGrid from '../../components/customDataGrid';
import { GridActionsCellItem } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Link, useNavigate } from 'react-router-dom';
import { TouchRippleRipple } from '@mui/material/ButtonBase/TouchRipple';
import ExpandingAvatar from '../../components/ExpandingAvatar';
import { post } from '../../services/services';
import axios from 'axios';
import CustomizedAlertBar from '../../components/customAlertBar';
import { useLanguage } from '../../context/LanguageProvider';
import { Box, CircularProgress, Grid } from '@mui/material';
import { useAuth } from '../../context/AuthProvider';
import { StatisticsPaper, StatisticsPaper2 } from '../../components/customStatisticsPaper';

import { Tooltip } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const getStatusIcon = (image_id, seen_date, feedback) => {
	if (!image_id) {
	  return <FiberManualRecordIcon style={{ color: 'grey' }} />;
	}
	if (image_id && !seen_date) {
	  return <FiberManualRecordIcon style={{ color: 'blue' }} />;
	}
	const formattedDate = new Date(seen_date).toLocaleDateString();
  
	if (image_id && seen_date && !feedback) {
	  return (
		<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
		  <VisibilityOutlinedIcon style={{ color: 'blue' }} />
		  <span>{`Seen on: ${formattedDate}`}</span>
		</div>
	  );
	}
	if (image_id && seen_date && feedback === 'agree') {
	  return (
		<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
		  <VisibilityOutlinedIcon style={{ color: 'green' }} />
		  <span>{`Seen on: ${formattedDate}`}</span>
		</div>
	  );
	}
	if (image_id && seen_date && feedback === 'disagree') {
	  return (
		<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
		  <VisibilityOutlinedIcon style={{ color: 'red' }} />
		  <span>{`Seen on: ${formattedDate}`}</span>
		</div>
	  );
	}
	return null;
  };

  
const Patients = (props) => {
  const { getLangString } = useLanguage();
  const navigate = useNavigate();

  const { tokenData } = useAuth();
  const isAdmin = tokenData?.role === 'admin';

  const [data, set_data] = useState({});
  const [loading, set_loading] = useState(true);

  const childRef = useRef(null);
  const alertRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      const urlParams = { resource: 'user', action: 'getStatisticsByClinic' };
      const result = await post(urlParams);
      set_data(result.data);
      set_loading(false);
    };
    if (!isAdmin) fetchData().catch(console.error);
  }, []);

  const editUser = (params) => () => {
    navigate('/EditPatient', { state: { params: params.row } });
  };

  const handleChangeProfilePic = async (event, user_id) => {
    try {
      const urlParams = { resource: 'user', action: 'getProfileUploadUrlGeneric' };
      const res = await post(urlParams, {
        user_id
      });

      console.log('first', event, user_id);

      const url = res.data;
      const file = event.target.files[0];

      var instance = axios.create();
      instance
        .put(url, file)
        .then(async function (result) {
          if (result.status === 200) {
            childRef.current.handleClose();
            alertRef.current.handleClick({
              message: 'UPDATED! PAGE WILL REFRESH SOON!',
              severity: 'success'
            });
            setTimeout(function () {
              window.location.reload(false);
            }, 10000);
          }
        })
        .catch(function (err) {
          alertRef.current.handleClick({
            message: 'PHOTO NOT UPDATED!',
            severity: 'error'
          });
          console.log('catch', err);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const columns = [
    { field: 'patient_id', headerName: getLangString('id'), width: 40 },
    { field: 'patient_clinic_id', headerName: getLangString('clinic_id'), minWidth: 90 },
    { field: 'patient_user_id', headerName: getLangString('user_id'), minWidth: 90 },
    {
      field: 'image',
      headerName: getLangString('profile'),
      width: 120,
      renderCell: (params) => (
        <ExpandingAvatar
          ref={childRef}
          image_thumb={params.row.profile_pic}
          image_url={params.row.profile_pic}
          onChange={(event) => handleChangeProfilePic(event, params.row.patient_user_id)}
        />
      )
    },
    {
      field: 'patient_email',
      headerName: getLangString('email'),
      flex: 1,
      minWidth: 150
    },
    {
      field: 'patient_fname',
      headerName: getLangString('first_name'),
      flex: 1,
      minWidth: 110
    },
    {
      field: 'patient_mname',
      headerName: getLangString('middle_name'),
      flex: 1,
      minWidth: 110
    },
    {
      field: 'patient_lname',
      headerName: getLangString('last_name'),
      flex: 1,
      minWidth: 130
    },
    {
      field: 'patient_dob',
      headerName: getLangString('birthday'),
      flex: 1,
      minWidth: 130
    },
    {
      field: 'patient_gender',
      headerName: getLangString('gender'),
      flex: 1,
      minWidth: 90
    },
    {
      field: 'patient_phone',
      headerName: getLangString('phone'),
      flex: 1,
      minWidth: 100
    },
    {
      field: 'patient_contact_email',
      headerName: getLangString('contact_email'),
      flex: 1,
      minWidth: 130
    },
    {
      field: 'patient_brief',
      headerName: getLangString('note'),
      flex: 1,
      minWidth: 130
    },
    {
      field: 'patient_address',
      headerName: getLangString('address'),
      flex: 1,
      minWidth: 150
    },
    {
      field: 'prediction',
      headerName: getLangString('last_prediction'),
      flex: 1,
      minWidth: 90,
      renderCell: (cellValues) => {
        switch (cellValues.value) {
          case 'normal':
            return (
              <div
                style={{
                  color: 'green'
                }}>
                Standard Risk
              </div>
            );
          case 'complicated':
            return (
              <div
                style={{
                  color: 'red'
                }}>
                Elevated Risk
              </div>
            );
          case 'face_not_found':
            return (
              <div
                style={{
                  color: 'orange'
                }}>
                Unclear Photo
              </div>
            );
          default:
            return <div>No Prediction</div>;
        }
      }
    },
    // {
    //   field: 'status',
    //   headerName: getLangString('status'),
    //   flex: 1,
    //   minWidth: 200,
    //   renderCell: (params) => {
    //     const currentTime = new Date(params.row.patient_dob).toLocaleString();
    //     return <span>{currentTime}</span>;
    //   }
    // },
	{
		field: 'statusIcon',
		headerName: 'Status',
		width: 200, // Adjust width as needed
		renderCell: (params) => {
		  const { image_id, seen_date, feedback } = params.row;
		  return (
			<div style={{ display: 'flex', alignItems: 'center' }}>
			  {getStatusIcon(image_id, seen_date, feedback)}
			</div>
		  );
		},
	  },
    {
      field: 'patient_history',
      headerName: getLangString('surgery_history'),
      flex: 1,
      minWidth: 150,
      type: 'actions',
      renderCell: (params) => (
        <>
          <Link
            to={`/patientHistory/${params.row.patient_user_id}`}
            style={{ textDecoration: 'none' }}>
            <Button
              variant="contained"
              size="small"
              style={{ marginLeft: 16, backgroundColor: '#1db14c' }}>
              <VisibilityOutlinedIcon style={{ fontSize: 20 }} />
              {getLangString('view')}{' '}
            </Button>
          </Link>
        </>
      )
    },
    {
      field: 'actions',
      headerName: getLangString('action'),
      type: 'actions',
      minWidth: 120,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<ModeEditOutlineOutlinedIcon />}
          label="Edit"
          onClick={editUser(params)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
        // onClick={deleteUser(params.id)}
        />
      ]
    }
  ];
  const rowID = (row) => row.patient_id;
  const urlParams = { resource: 'user', action: 'getAllPatients' };
  const createPage = {
    buttonText: `+ ${getLangString('add_new_patient')}`,
    createPagePath: '/NewPatient'
  };
  const columnVisibilityModel = {
    patient_id: false,
    patient_clinic_id: false,
    patient_email: false,
    patient_phone: false,
    patient_brief: false,
    patient_user_id: false,
    patient_mname: false,
    patient_address: false,
    patient_contact_email: false,
    patient_dob: false
  };
  const page = 'patientList';
  return !isAdmin && loading ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        width: '100%'
      }}>
      <CircularProgress />
    </Box>
  ) : (
    <>
      <CustomizedAlertBar ref={alertRef} />

      {!isAdmin && (
        <Box sx={{ mt: 3, ml: 3, display: 'flex', height: '100%', width: '100%' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <StatisticsPaper
                name={getLangString('stat_total_providers')}
                value={data.doctors[0].doctors_total}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <StatisticsPaper
                name={getLangString('stat_total_patients')}
                value={data.patients[0].patients_total}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <StatisticsPaper2
                name={getLangString('stat_total_procedures')}
                value={data.procedures[0].procedures_total}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <StatisticsPaper2
                name={getLangString('stat_active_procedures')}
                value={data.active_procedures[0].active_procedures_total}
              />
            </Grid>
          </Grid>
        </Box>
      )}

      <ListGrid
        rowID={rowID}
        columns={columns}
        urlParams={urlParams}
        createPage={createPage}
        columnVisibilityModel={columnVisibilityModel}
        pageName={page}
      />
    </>
  );
};

const RenderButton = (props) => {
  const { hasFocus, value, params, patientHistory } = props;
  const buttonElement = (React.useRef < HTMLButtonElement) | (null > null);
  const rippleRef = (React.useRef < TouchRippleRipple) | (null > null);

  React.useLayoutEffect(() => {
    if (hasFocus) {
      const input = buttonElement.current?.querySelector('input');
      input?.focus();
    } else if (rippleRef.current) {
      // Only available in @mui/material v5.4.1 or later
      // rippleRef.current.stop({} as any);
    }
  }, [hasFocus]);

  return (
    <strong>
      <Button
        variant="contained"
        size="small"
        style={{ marginLeft: 16, backgroundColor: '#1db14c' }}
        onClick={patientHistory(params)}
        tabIndex={params.hasFocus ? 0 : -1}>
        <VisibilityOutlinedIcon style={{ fontSize: 20 }} />
        View{' '}
      </Button>
    </strong>
  );
};

export default Patients;
